import React from "react";
import TabsComponent from "../tabs/TabsComponent";
import "./pages.css";

const Solutii = () => {
  return (
    <div className="pages-container">
      <TabsComponent />
    </div>
  );
};

export default Solutii;
