import React from "react";
import CardsContainer from "../cardscontainer/CardsContainer";
import "./pages.css";

const Servicii = () => {
  return (
    <div className="pages-container">
      <CardsContainer />
    </div>
  );
};

export default Servicii;
